<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <DataTable
          :value="this.commentaries"
          :paginator="true"
          :rows="10"
          :loading="this.loading"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="{first} à {last} des {totalRecords} commentaires"
          responsiveLayout="scroll"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <h5 class="m-0">Liste des commentaires</h5>
            </div>
          </template>
          <Column
            field="content"
            header="Commentaire"
            :sortable="false"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <p v-html="slotProps.data.content" />
            </template>
          </Column>
          <Column
            field="spectacleName"
            header="Spectacle"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <p v-html="slotProps.data.spectacleName" />
            </template>
          </Column>
          <Column
            field="author"
            header="Auteur"
            :sortable="false"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <p v-html="slotProps.data.author" />
            </template>
          </Column>
          <Column
            field="dateCreated"
            header="Date de création"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <p v-html="slotProps.data.dateCreated" />
            </template>
          </Column>
          <Column
            field="note"
            header="Note"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <Rating :modelValue="slotProps.data.note" disabled />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import CommentaryService from "@/service/CommentaryService";
import useVuelidate from "@vuelidate/core";

export default {
  components: {},

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      commentaries: [],
    };
  },

  commentaryService: null,
  created() {
    this.commentaryService = new CommentaryService();
  },

  mounted() {
    this.commentaryService.getAllcommentaries().then((data) => {
      this.commentaries = data;
      this.loading = false;
    });
  },
  methods: {},
};
</script>
